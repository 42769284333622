<template>
  <div class="bgcontent">
    <Header style="background: none">
      <template slot="title">卖出</template>
    </Header>
    <div class="sale">
      <div class="tiptxt" v-html="desc"></div>
      <div class="card margin24">
        <div class="box">
          <div class="num">{{ banner }}</div>
          <div class="txt">可出售数量({{ coin }})</div>
        </div>
        <div class="payway margin32">
          <div class="top">
            <div class="txt">选择支付方式</div>
            <div class="txt1">支付方式 ></div>
          </div>

          <div class="paytype margin16">
            <div
              :class="payArr.indexOf('2') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('2')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/zhifubao.png')"
              />
              <div class="tip">支付宝</div>
            </div>
            <div v-if="coin!='USDT'"
              :class="payArr.indexOf('1') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('1')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/usdt.png')"
              />
              <div class="tip">USDT</div>
            </div>
            <div></div>
            <div
              :class="payArr.indexOf('3') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('3')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/card.png')"
              />
              <div class="tip">银行卡</div>
            </div>
          </div>
          <div class="buynum margin16">
            <van-field
              readonly
              class="inputClass"
              v-model="coin"
              placeholder="选择一个资产"
              @click="clickInput"
            />
            <van-field
              class="inputClass"
              v-model="salenum"
              name="salenum"
              placeholder="请输入出售数量"
              autocomplete="off"
            ></van-field>
            <van-field
              class="inputClass"
              v-model="saleprice"
              name="saleprice"
              placeholder="请输入出售USD价格"
              autocomplete="off"
            ></van-field>
            <van-field
                    class="inputClass"
                    :value="saleprice>0?saleprice*parseFloat(tradeInfo.cny_to_us)+' CNY':'出售CNY价格'"
                    readonly
                    placeholder="出售CNY价格"
                    autocomplete="off"
            ></van-field>
            <!--<van-field-->
              <!--class="inputClass"-->
              <!--v-model="buymin"-->
              <!--name="salebuymin"-->
              <!--placeholder="请输入最低购买人民币"-->
              <!--autocomplete="off"-->
            <!--&gt;</van-field>-->
            <!--<van-field-->
              <!--class="inputClass"-->
              <!--v-model="buymax"-->
              <!--name="salebuymax"-->
              <!--placeholder="请输入最高购买人民币"-->
              <!--autocomplete="off"-->
            <!--&gt;</van-field>-->
            <div class="notice"  v-if="coin!='USDT'">
              注意：挂卖价格最高不能超过{{ max_price }}USD，最低不能低于{{
                min_price
              }}USD
            </div>
          </div>
          <div class="btncell margin48">
            <div class="btn" @click="clickSubmit">确认下单</div>
          </div>
        </div>
      </div>
      <div class="step margin24">
        <div class="stepbox">
          <div class="tipbox">第一步</div>
          <div class="txt margin16">
            1.添加支付方式 <br />
            2.填写出售信息 <br />
            3.挂单出售
          </div>
        </div>
        <div class="stepbox">
          <div class="tipbox">第二步</div>
          <div class="txt margin16">
            1.匹配成功,查看订单记录 <br />
            2.等待对方付款 <br />
            3.收款后放行
          </div>
        </div>
      </div>
    </div>
    <van-picker
      class="picker"
      v-if="showPicker"
      show-toolbar
      title="选择资产"
      :columns="columns"
      :default-index="0"
      @cancel="onCancel"
      @confirm="onConfirm"
    />
    <van-dialog
      v-model="showpwd"
      title="确认卖出"
      @cancel="pwdShow"
      @confirm="clickhangSell"
      show-cancel-button
    >
      <van-field
        type="password"
        v-model="password"
        name="安全密码"
        label="安全密码"
        placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import {
  getOptionDesc,
  hangSell,
  getWalletInfo,
  tradeStatistics,
    getTradeConfig
} from "@/request/api";
import Header from "../../components/header.vue";
import { Toast } from "vant";
import $ from "jquery";

export default {
  components: {
    Header,
  },
  data() {
    return {
      showPicker: false,
      salenum: "",
      coin: "",
      saleprice: "",
      salelimit: "",
      buymin: "",
      buymax: "",
      columns: ["USDT"],
      desc: "",
      userInfo: {},
      payArr: [],
      banner: 0,
      walletList: [],
      showpwd: false,
      password: "",
      tradeInfo: {},
      max_price: 0,
      min_price: 0,
        tradeCofig:{},
    };
  },
  activated() {
      this.coin = this.$route.query.coin;
    /*  if(this.coin=='USDT'){
          this.saleprice =1;
      }*/
    this.address = sessionStorage.getItem("address");
    this.userInfo = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.getOptionDesc();
    this.getWalletList();
    this.gettradeStatistics();
      this.gettradeConfig();
  },
  methods: {
      gettradeConfig(){
          getTradeConfig().then((res) => {
              this.tradeCofig = res.data;
          });
      },
    gettradeStatistics() {
      const params = {
        coin_symbol: this.coin,
        // address: this.$store.state.user.userInfo.address,
      };
      tradeStatistics(params).then((res) => {
        this.tradeInfo = res.data;
        if (this.coin == "ATT") {
          this.max_price = res.data.trade_wave.attmax;
          this.min_price = res.data.trade_wave.attmin;
        } else if (this.coin == "EPT") {
          this.max_price = res.data.trade_wave.eptmax;
          this.min_price = res.data.trade_wave.eptmin;
        } else if (this.coin == "ARR") {
          this.max_price = res.data.trade_wave.arrmax;
          this.min_price = res.data.trade_wave.arrmin;
        }
        else if (this.coin == "USDT") {
            this.max_price = 0;
            this.min_price = 0;
        }
      });
    },
    pwdShow() {
      this.showpwd = false;
    },
    getWalletList() {
      let _this = this;
      const params = {
        address: this.address,
      };
      getWalletInfo(params).then((res) => {
        $.each(res.data.list, function (i, ele) {
          _this.walletList[ele.coin_symbol] = parseFloat(
            ele.cloud_balance
          ).toFixed(4);
        });
        console.log(_this.walletList);
        _this.banner = _this.walletList[_this.coin];
      });
    },
    clickhangSell() {
      let _this = this;
      if (!this.password) {
        Toast("请输入密码");
        return;
      }
      const params = {
        coin_symbol: this.coin,
        address: this.address,
        pay_code: this.password,
        buy_pay_type: this.payArr.join(","),
        amount: this.salenum,
        price: this.saleprice,
        min_price: this.buymin,
        max_price: this.buymax,
      };
      hangSell(params).then((res) => {
        if (res.code == 200) {
          this.salenum = "";
          this.password = "";
          this.saleprice = "";
          this.buymin = "";
          this.buymax = "";
          this.payArr = [];
          Toast("挂卖成功");
          setTimeout(function () {
            _this.$router.push({
              path: "/task/hanglog",
              query: {
                coin: _this.coin,
              },
            });
          }, 2500);
        } else {
          Toast(res.msg);
        }
      });
    },
    selectPay(value) {
      let index = this.payArr.indexOf(value);
      if (index === -1) {
        this.payArr.push(value);
      } else {
        this.payArr.splice(index, 1);
      }
    },
    getOptionDesc() {
      const params = {
        name: "trade_desc",
        address: this.$store.state.user.userInfo.address,
      };
      getOptionDesc(params).then((res) => {
        this.desc = res.data.value_zh;
      });
    },
    onCancel() {
      this.showPicker = false;
    },
    clickInput() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.showPicker = false;
      this.inputtopup = value;
      this.coin = value;
      this.gettradeStatistics();
      this.banner = this.walletList[this.coin];
    },
    clickSubmit() {
      let _this = this;
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return;
      }
      if (this.address && this.userInfo.has_paypwd != 1) {
        Toast("请先完善资料,设置安全密码");
        setTimeout(function () {
          _this.$router.push({
            path: "/home/mine",
          });
        });
        return;
      }
      if (this.coin.length === 0) {
        Toast("请选择资产");
        return;
      }
      if (this.salenum.length === 0) {
        Toast("请输入求购数量");
        return;
      }
      if (this.saleprice.length === 0) {
        Toast("请输入求购价格");
        return;
      }
      // if (this.buymin.length === 0) {
      //   Toast("请输入最低购买人民币");
      //   return;
      // }
      // if (this.buymax.length === 0) {
      //   Toast("请输入最高购买人民币");
      //   return;
      // }
        // * (1 + parseFloat(this.userInfo.fee))
      if (this.payArr.length === 0) {
        Toast("请选择支付方式");
        return;
      }
      if (this.coin == "ATT") {
        let useNum =
          parseFloat(this.salenum);
          if(parseFloat(useNum)>(parseFloat(this.banner)-1)){
              Toast('用户可出售最低需剩余1ATT！');
              return;
          }
      }
      if (this.coin == "ATT" && this.buynum < 2) {
        Toast("ATT最低挂单数量为2");
        return false;
      }
      if(this.coin == "USDT"&&parseFloat(this.buynum)>parseFloat(this.tradeCofig.shop_usdt_limit)){
          Toast("USDT最大挂卖"+this.tradeCofig.shop_usdt_limit);
          return false;
      }

      // 判断是否在交易时间
        var nowTime = (new Date).getTime() / 1000;
        if(this.coin == 'USDT'){
            if (nowTime > parseInt(this.tradeInfo.endUsdtTimestamp) || nowTime < parseInt(this.tradeInfo.startUsdtTimestamp)) {
                Toast('今日交易时间段未开启或已结束');
                return false;
            }
        }else{
            if (nowTime > parseInt(this.tradeInfo.endTimestamp) || nowTime < parseInt(this.tradeInfo.startTimestamp)) {
                Toast('今日交易时间段未开启或已结束');
                return false;
            }
        }
      this.showpwd = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.picker {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.margin24 {
  margin-top: 24px;
}

.margin48 {
  margin-top: 48px;
}

.margin32 {
  margin-top: 32px;
}

.margin16 {
  margin-top: 16px;
}

.bgcontent {
  background: rgba(13, 25, 18, 1);
  min-height: 100vh;
  .sale {
    padding: 32px;
    .tiptxt {
      opacity: 1;
      color: rgba(139, 166, 149, 1);
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      line-height: 44px;
      font-family: "PingFang SC";
    }
    .card {
      padding: 24px;
      width: 686px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(16, 43, 29, 1);
      .box {
        width: 638px;
        height: 168px;
        border-radius: 16px;
        opacity: 1;
        border: 2px solid rgba(39, 204, 127, 0.2);
        background: rgba(41, 51, 45, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          color: rgba(214, 255, 227, 1);
          font-size: 44px;
          font-weight: 500;
          font-family: "DIN";
          text-align: center;
          line-height: 60px;
        }
        .txt {
          color: rgba(182, 217, 195, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 36px;
        }
      }
      .payway {
        .top {
          display: flex;
          justify-content: space-between;
          .txt {
            color: rgba(214, 255, 227, 1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
          }
          .txt1 {
            color: rgba(39, 204, 127, 1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
          }
        }

        .paytype {
          display: flex;
          /*justify-content: space-between;*/
          justify-content: space-around;
          .typebox {
            width: 188px;
            height: 72px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(139, 166, 149, 1);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .payimg {
              width: 40px;
              height: 40px;
            }
            .tip {
              color: rgba(139, 166, 149, 1);
              font-size: 32px;
              font-weight: 700;
              font-family: "PingFang SC";
            }
          }
          .selectBox {
            width: 188px;
            height: 72px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(39, 204, 127, 1);
            background: rgba(39, 204, 127, 0.2);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .payimg {
              width: 40px;
              height: 40px;
            }
            .tip {
              color: rgba(39, 204, 127, 1);
              font-size: 32px;
              font-weight: 700;
              font-family: "PingFang SC";
            }
          }
        }
        .buynum {
          .inputClass {
            background: #29332d;
            ::v-deep .van-field__control {
              color: white;
            }
          }
        }
        .btncell {
          display: flex;
          justify-content: center;
          .btn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39, 204, 127, 1);
            color: rgba(32, 40, 35, 1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
          }
        }
        .tip1 {
          color: rgba(139, 166, 149, 1);
          font-size: 28px;
          font-weight: 700;
          text-align: center;
        }
        .notice {
          margin-top: 8px;
          color: rgba(39, 199, 204, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 36px;
        }
      }
    }

    .step {
      display: flex;
      justify-content: space-between;
      .stepbox {
        width: 328px;
        height: 284px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32, 40, 35, 1);
        padding: 24px;
        .tipbox {
          width: 104px;
          height: 44px;
          border-radius: 8px;
          opacity: 1;
          border: 2px solid rgba(39, 199, 204, 1);
          color: rgba(39, 199, 204, 1);
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          line-height: 44px;
        }
        .txt {
          color: rgba(139, 166, 149, 1);
          font-size: 24px;
          font-weight: 400;
          line-height: 44px;
        }
      }
    }
  }
}
</style>